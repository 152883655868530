import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/wigilia1.jpg";
import Lightbox from "../components/lightBox";

export const query = graphql`
  {
    wigilia1: file(relativePath: { eq: "wigilia1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    wigilia2: file(relativePath: { eq: "wigilia2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    wigilia3: file(relativePath: { eq: "wigilia4.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    wigilia4: file(relativePath: { eq: "wigilia8.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    wigilia5: file(relativePath: { eq: "wigilia10.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Specrm = ({ data }) => {
  return (
    <Artykul
      title="Wigilia w Questy 🎄"
      keywords={["wigilia questy"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Spotkanie świąteczne w biurze Questy"
      metaTitle="Wigilia w Questy"
      metaDescription="Spotkanie świąteczne w biurze Questy"
    >
      <br />
      <p>
        Piątkowy wieczór w naszym biurze upłynął w świątecznej atmosferze 🎄.
        Podsumowaliśmy ostatni rok i spędziliśmy razem wspaniały czas 🌟.
        Przesyłamy klika zdjęć z tego wydarzenia 📸. Dziękujemy wszystkim,
        którzy je uświetnili! 🧡
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
        }}
        images={[
          data.wigilia1,
          data.wigilia2,
          data.wigilia3,
          data.wigilia4,
          data.wigilia5,
        ]}
        alts={["wigilia questy "]}
      />
      <br />
    </Artykul>
  );
};

export default Specrm;
